<template>
    <div class="setting-card ">
        <div class="doc-content-wrapper">
            <div class="doc-content-container">
                <h1 id="form-表单" tabindex="-1">Two API - 应用演示
                </h1>
                <div class="tip custom-block">
                    <h2 style="padding-top: 10px;">个人站长参与接入测试: 一步接入AI</h2>
                    <p>只需一步，您就可以在自己的网站中免费接入TWOAPI, 复制以下脚本添加到您页面的body中
                        <br />
                        <textarea
                            style="width: 90%;height: 30px;"><script src="/scripts/content.js"></script></textarea>
                        <br />您就能获得一个和 <a href="http://suishouji.qiangtu.com/"
                            target="_blank">http://suishouji.qiangtu.com/</a>
                        功能一模一样的AI，其功能为：
                    </p>
                    <ul>
                        <li> 页面内任意地方选取文字，都可以弹出对话框基于选择的内容进行AI互动。</li>
                        <li> 在右下角新增一个AI的对话按钮，点击之后唤起AI互动界面，支持基于AI来进行当前页面总结和任意的AI对话功能。</li>
                    </ul>
                    <h2>测试参与说明</h2>
                    <p>本站点仅用于测试和展示通过TWOAPI调用来进行的衍生应用。</p>
                    <ul>
                        <li>测试站点不保证稳定性，迭代的过程会导致停机和数据丢失。</li>
                        <li>测试站点并非法外之地，数据和内容依然处于被监管的范围。</li>
                        <li>请勿放飞自我，使用合理的数据进行测试。</li>
                        <li>没有Token或者测试碰到问题可以微信群求助
                            <br />
                            <h2><a href="http://qr61.cn/oRUvxf/qyT8mJT" target="_blank">加微信群点我</a></h2>
                        </li>
                    </ul>

                    <div class='typora-export-content'>
                        <div id='write' class=''>
                            <h1 id='有效应用领域'><span>有效应用领域</span></h1>
                            <p>&nbsp;</p>
                            <h2 id='twoapi衍生验证清单'><span>TWOAPI衍生验证清单</span></h2>
                            <ul>
                                <li>
                                    <p><span>对话：【</span><a href='https://www.8ai.link/'><span>NetWeb UI
                                                测试</span></a><span>】最强模型【</span><a
                                            href='/chat/base/13/186'><span>o1-preview</span></a><span>】【</span><a
                                            href='/chat/base/122/160'><span>Claude-3.5</span></a><span>】【</span><a
                                            href='/chat/base/3/35'><span>ERNIE-Bot-Pro</span></a><span>】【</span><a
                                            href='/chat/base/12/181'><span>glm-4-long</span></a><span>】开源【</span><a
                                            href='https://github.com/520hacker/two-api'><span>Two API
                                                UI源码</span></a><span>】</span></p>
                                </li>
                                <li>
                                    <p><span>翻译：【</span><a
                                            href='/chat/base/1/164'><span>语法优化</span></a><span>】【</span><a
                                            href='/chat/base/7/164'><span>中英互译</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>文案：【</span><a
                                            href='/chat/base/118/77/dse9iXvor'><span>董宇辉风格</span></a><span>】【</span><a
                                            href='/chat/base/107/72'><span>小红书</span></a><span>】【</span><a
                                            href='/chat/base/108/11'><span>小红书2</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>微信：清单【 </span><a
                                            href='https://github.com/520hacker/awesome-ai/blob/main/%E5%BE%AE%E4%BF%A1ChatGPT.md'><span>主流微信BOT方案</span></a><span>
                                            】音色加持【 </span><a
                                            href='https://odin.mblog.club/%E5%B0%8F%E5%A7%90%E5%A7%90%E8%AF%AD%E9%9F%B3'><span>福利：微信萌妹子语音</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>语音：【板块建设中...】</span></p>
                                </li>
                                <li>
                                    <p><span>论文：</span><strong><span>论文神器</span></strong><span>【</span><a
                                            href='/article/generator'><span>免费测试</span></a><span>】开源【</span><a
                                            href='https://github.com/520hacker/lunwenshenqi'><span>论文神器源码</span></a><span>】【</span><a
                                            href='https://odin.mblog.club/AI%E9%95%BF%E6%96%87%E7%94%9F%E6%88%90%E5%99%A8'><span>教学</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>助学：【</span><a
                                            href='https://note2exam.qiangtu.com/'><span>笔记测验</span></a><span>】开源【</span><a
                                            href='https://github.com/520hacker/note2exam'><span>笔记测验源码</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>读文件：【</span><a
                                            href='/chat/base/113/69'><span>基于文档对话</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>做图片： 【</span><a
                                            href='/chat/base/112/70'><span>识别图片</span></a><span>】【</span><a
                                            href='/chat/base/139/77/Wk77jayA0'><span>生成头像</span></a><span>】
                                            开源【</span><a
                                            href='https://github.com/520hacker/mj-chat-autorun'><span>批量出图源码</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>写歌：【</span><a
                                            href='/chat/base/127/159'><span>SUNO</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>做PPT：【</span><a
                                            href='/chat/base/131/77/Gt6Z8pqWF'><span>GPTS</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>做视频： 转发成本较高,不稳定:【</span><a
                                            href='/chat/base/141/180'><span>RUNWAY</span></a><span>】【</span><a
                                            href='/log/video/luma-video'><span>LUMA</span></a><span>】【</span><a
                                            href='/chat/base/140/177'><span>VIDU</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>游戏：【</span><a
                                            href='/chat/base/120/164'><span>哄哄女友</span></a><span>
                                            】【
                                        </span><a
                                            href='/chat/base/143/164'><span>辩论会</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>卡片：【</span><a
                                            href='/chat/base/142/164'><span>答案之书</span></a><span>】【</span><a
                                            href='/chat/base/144/164'><span>新解词语卡</span></a><span>】</span><span>【</span><a
                                            href='https://txt2img1.qiangtu.com/' target="_blank"><span>flux名词卡片</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>人物：【</span><a
                                            href='/chat/base/106/139'><span>禅师</span></a><span>】【</span><a
                                            href='/chat/base/5/138'><span>祖母</span></a><span>】【</span><a
                                            href='/chat/base/135/77/984qxMQkT'><span>半仙</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>插件：【</span><a
                                            href='https://yibu.qiangtu.com/'><span>一步</span></a><span>】开源【</span><a
                                            href='https://github.com/520hacker/markdown-AI-editor/tree/main/source-vue'><span>ECHO-一步</span></a><span>】应用：【</span><a
                                            href='https://suishouji.qiangtu.com/'><span>随手记</span></a><span>】</span></p>
                                </li>
                                <li>
                                    <p><span>编程：开源【</span><a
                                            href='https://github.com/520hacker/CodeCommenter'><span>给整个项目添加注释源码</span></a><span>】</span>
                                    </p>
                                </li>
                            </ul>
                            <h3 id='汇总本站点已开源模块'><span>汇总本站点已开源模块：</span></h3>
                            <ul>
                                <li>
                                    <p><span>开源【</span><a
                                            href='https://github.com/520hacker/CodeCommenter'><span>给整个项目添加注释
                                                源码</span></a><span>】</span></p>
                                </li>
                                <li>
                                    <p><span>开源【</span><a href='https://github.com/520hacker/two-api'><span>Two API UI
                                                源码</span></a><span>】</span></p>
                                </li>
                                <li>
                                    <p><span>开源【</span><a href='https://github.com/520hacker/lunwenshenqi'><span>论文神器
                                                源码</span></a><span>】</span></p>
                                </li>
                                <li>
                                    <p><span>开源【</span><a href='https://github.com/520hacker/note2exam'><span>笔记测验
                                                源码</span></a><span>】</span></p>
                                </li>
                                <li>
                                    <p><span>开源【</span><a href='https://github.com/520hacker/mj-chat-autorun'><span>批量出图
                                                源码</span></a><span>】</span></p>
                                </li>
                                <li>
                                    <p><span>开源【</span><a
                                            href='https://github.com/520hacker/markdown-AI-editor'><span>随手记
                                                源码</span></a><span>】</span></p>
                                </li>
                            </ul>
                            <h3 id='其他开源项目'><span>其他开源项目</span></h3>
                            <ul>
                                <li>
                                    <p><span>开源【</span><a href='https://github.com/520hacker/Today.WordCloud'><span>今日词云
                                                源码</span></a><span>】</span></p>
                                </li>
                                <li>
                                    <p><span>开源【</span><a
                                            href='https://github.com/520hacker/qrcode_maper'><span>AI摆摊海报</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>开源【</span><a
                                            href='https://github.com/520hacker/rss-square'><span>RSS广场</span></a><span>】</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span>开源【</span><a
                                            href='https://github.com/520hacker/img2oss'><span>图片协议转存OSS</span></a><span>】</span>
                                    </p>
                                </li>
                            </ul>
                            <p>&nbsp;</p>
                            <h2 id='国产ai'><span>国产AI</span></h2>
                            <figure class='table-figure'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th><span>厂家</span></th>
                                            <th><span>主站MODEL</span></th>
                                            <th><span>Free</span></th>
                                            <th><span>开源</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a href='https://aistudio.baidu.com/index'><span>百度</span></a></td>
                                            <td><a
                                                    href='/chat/base/3/35'><span>ERNIE-Bot-Pro</span></a><span>,</span><a
                                                    href='/chat/base/3/34'><span>ERNIE-Bot-turbo</span></a><span>,</span><a
                                                    href='/chat/base/3/33'><span>ERNIE-Bot</span></a>
                                            </td>
                                            <td><a
                                                    href='/chat/base/3/147'><span>ERNIE-Lite</span></a><span>,</span><a
                                                    href='/chat/base/3/146'><span>ERNIE-Speed</span></a><span>,</span><a
                                                    href='/chat/base/3/148'><span>ERNIE-Tiny</span></a>
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td><a href='https://bailian.console.aliyun.com/'><span>阿里</span></a></td>
                                            <td><a
                                                    href='/chat/base/115/78'><span>qwen-max</span></a><span>,</span><a
                                                    href='/chat/base/115/114'><span>qwen-2-70b</span></a><span>,</span><a
                                                    href='/chat/base/115/192'><span>qwen-long</span></a>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td><a
                                                    href='https://www.ollama.com/library/qwen2'><span>qwen2-72b-instruct</span></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href='https://hunyuan.tencent.com/'><span>腾讯</span></a></td>
                                            <td><a
                                                    href='/chat/base/134/87'><span>hunyuan</span></a>
                                            </td>
                                            <td><a
                                                    href='/chat/base/5/138'><span>yuanqi</span></a><span>,hunyuan-lite</span>
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td><span>月之暗面</span></td>
                                            <td><a
                                                    href='/chat/base/116/127'><span>kimi</span></a><span>,</span><a
                                                    href='/chat/base/116/85'><span>moonshot-v1-128k</span></a>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td><span>DEEPPSEEK</span></td>
                                            <td><a
                                                    href='/chat/base/130/131'><span>deepseek-chat</span></a><span>,</span><a
                                                    href='/chat/base/130/132'><span>deepseek-coder</span></a>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td><a
                                                    href='https://www.ollama.com/library/deepseek-v2.5'><span>deepseek-v2.5</span></a><span>
                                                </span><a
                                                    href='https://www.ollama.com/library/deepseek-coder-v2'><span>deepseek-coder-v2</span></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span>智谱</span></td>
                                            <td><a
                                                    href='/chat/base/12/99'><span>glm-4</span></a>
                                            </td>
                                            <td><a
                                                    href='/chat/base/12/191'><span>GLM-4-Flash</span></a>
                                            </td>
                                            <td><a href='https://www.ollama.com/library/glm4'><span>glm4-9B</span></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span>讯飞</span></td>
                                            <td><a
                                                    href='/chat/base/123/108'><span>generalv3.5</span></a>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td><span>百川</span></td>
                                            <td><span>Baichuan4,Baichuan3-Turbo,Baichuan3-Turbo-128k</span></td>
                                            <td>&nbsp;</td>
                                            <td><a
                                                    href='https://www.ollama.com/maxkb/baichuan2'><span>baichuan2-13b-chat-v1</span></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span>360</span></td>
                                            <td><a
                                                    href='/chat/base/11/173'><span>360gpt2-pro</span></a><span>,</span><a
                                                    href='/chat/base/11/175'><span>360gpt-turbo</span></a><span>,</span><a
                                                    href='/chat/base/11/46'><span>360GPT_S2_V9.4</span></a>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td><span>零一万物</span></td>
                                            <td><a
                                                    href='/chat/base/124/122'><span>yi-vl-plus</span></a><span>,</span><a
                                                    href='/chat/base/124/121'><span>yi-34b-chat-200k</span></a><span>,yi-large,yi-large-turbo,yi-large-rag,yi-medium</span>
                                            </td>
                                            <td><span>Yi-1.5-6B-Chat,Yi-1.5-9B-Chat-16K</span></td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td><span>STEP</span></td>
                                            <td><a
                                                    href='/chat/base/128/188'><span>step-2-16k</span></a>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td><span>MINMAX</span></td>
                                            <td><span>abab6.5s-245k,abab6.5t-8k,abab6.5g-8k</span></td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td><span>字节</span></td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td><span>其他</span></td>
                                            <td>&nbsp;</td>
                                            <td><a
                                                    href='/chat/base/129/126'><span>wbot-2</span></a>
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </figure>
                            <p><span>价格对比参考: </span><a href='https://www.llmintro.com/' target='_blank'
                                    class='url'>https://www.llmintro.com/</a></p>
                        </div>
                    </div>

                </div>

                <div class="example">
                    <p class="custom-block-title">说明</p>
                    <p>本站点是为了回答企业以下问题而创建的DEMO合集，用于测试和展示通过TWOAPI调用来进行的衍生应用。</p>
                    <ul>
                        <li>企业在AI时代如何使用AI来降本增效。</li>
                        <li>职业者如何使用AI来提升竞争力。</li>
                    </ul>
                    <p class="custom-block-title">配置令牌</p>
                    <ul>
                        <li>
                            <p>第1步： 请确认您拥有了正确的 twoapi Key。</p>
                        </li>
                        <li>
                            <p>第2步： 更新 twoapi Key <el-input v-model="key" :rows="2" type="textarea"
                                    placeholder="Please input" /></p>
                        </li>
                        <li>
                            <p>第3步： 点击 按钮 <el-button type="primary" @click="setKey">更新</el-button></p>
                        </li>
                        <li>
                            当前密钥支持以下模型:
                            <p v-loading="showLoading">
                                <span v-for="(item, index) in mainModels" :key="index">
                                    <router-link :to="getHref(item.id)">{{ item.label }}</router-link> |
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>选择左侧菜单，找到对话框，输入你的问题，进入 DEMO 测试吧</p>
                        </li>
                    </ul>
                </div>
            </div>

            <aside class="toc-wrapper">
                <nav class="toc-content">
                    <h3 class="toc-content__heading">导航</h3>
                    <ul class="toc-items">
                        <li class="toc-item">
                            <router-link to="/" class="toc-link">
                                <p class="link-text">首页</p>
                            </router-link>
                        </li>
                    </ul>
                </nav>
            </aside>
        </div>
    </div>
</template>

<script>
import { getMainModels } from '@/utils/models'
import { getModels } from '@/api/model'
import { ElMessage } from 'element-plus'
import { ref } from 'vue';
import { getSharedToken } from '@/utils/token'
export default {
    name: 'HomePage',
    setup() {
        const key = ref('key')
        const showLoading = ref(false)
        const groupModels = ref([])
        const mainModels = ref([])

        const onLoad = () => {
            if (!localStorage.getItem('CSK') || localStorage.getItem('CSK') == '') {
                localStorage.setItem('CSK', getSharedToken())
            }

            key.value = localStorage.getItem('CSK')

            showLoading.value = true
            getModels({}).then(data => {
                groupModels.value = data.data
                mainModels.value = mainModels.value.filter(model => groupModels.value.some(models => models.id === model.label));

                if (!mainModels.value || mainModels.value == [] || mainModels.value.length < 1) {
                    mainModels.value = getMainModels(-1)

                    var mainModels2 = mainModels.value.filter(model => groupModels.value.some(models => models.id === model.label));

                    if (mainModels2 != null && mainModels2.length > 1) {
                        mainModels.value = mainModels2;
                    }
                }

                showLoading.value = false
            });
        }

        const getHref = (id) => {
            if (id == 59 || id == 74 || id == 75) {
                return '/chat/base/0/7';
            }

            return '/chat/base/0/' + id;
        }

        const setKey = () => {
            localStorage.setItem('CSK', key.value)
            onLoad();
            ElMessage({
                type: 'success',
                message: '已更新',
            })
        }

        onLoad();

        return {
            key,
            setKey,
            showLoading,
            getHref,
            mainModels
        };
    }
}
</script>